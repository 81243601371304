<template>
  <div>
    <RWCard>
      <!-- title cart -->
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><rect fill="#000" opacity=".3" x="13" y="4" width="3" height="16" rx="1.5"/><rect fill="#000" x="8" y="9" width="3" height="11" rx="1.5"/><rect fill="#000" x="18" y="11" width="3" height="9" rx="1.5"/><rect fill="#000" x="3" y="13" width="3" height="7" rx="1.5"/></g></svg>
          Daftar Pembayaran
        </h3>
      </template>

      <!-- body -->
      <template v-slot:body>
        <b-alert show variant="info">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis quo
          autem necessitatibus illo porro ea, nemo vel nesciunt fugiat sit
          expedita impedit saepe architecto dolor alias, id ipsa, pariatur quis?
        </b-alert>
        <b-row>
          <b-col sm="3">
            <b-form-group label="Start Date">
              <b-form-input type="date"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="End Date">
              <b-form-input type="date"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <div class="btnSearch">
              <b-button block variant="success">Pencarian</b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </RWCard>

    <b-card>
      <b-table striped hover :items="items" :fields="fields"></b-table>
      <b-pagination
        v-model="pagination.currentPage"
        :total-rows="pagination.rows"
        align="right"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
  components: {
    RWCard
  },
  data() {
    return {
      pagination: {
        rows: 100,
        currentPage: 3
      },
      items: [
        {
          no: 1,
          tanggal_pembayaran: "2020-01-02",
          rekening: "20200202-079878",
          jumlah: "Rp20.000"
        },
        {
          no: 2,
          tanggal_pembayaran: "2020-03-02",
          rekening: "20200402-079878",
          jumlah: "Rp70.000"
        },
        {
          no: 3,
          tanggal_pembayaran: "2020-05-05",
          rekening: "20200606-079878",
          jumlah: "Rp90.000"
        },
        {
          no: 4,
          tanggal_pembayaran: "2020-07-07",
          rekening: "20200810-079878",
          jumlah: "Rp890.000"
        }
      ],
      fields: [
        { key: "no", sortable: true },
        { key: "tanggal_pembayaran", sortable: true },
        { key: "rekening", sortable: true },
        { key: "jumlah", sortable: true }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Afiliasi" },
      { title: "Daftar Pembayaran" }
    ]);
  }
};
</script>

<style scoped>
@media screen and (min-width: 800px) {
  .btnSearch {
    margin-top: 18%;
  }
}
</style>
